<template>
  <div class="tc-questionnaire_classify">
    <!-- 头部 -->
    <!-- <div class="header">
      <div class="header-operation">
        <ul>
          <li>
            <router-link to="/control_questionnaire_result">
              <img src="../../../assets/images/questionnaireBtn.png" alt />
            </router-link>
          </li>
          <li>
            <router-link to="/question_bank">
              <img src="../../../assets/images/questionBankBtn.png" alt />
            </router-link>
          </li>
          <li>
            <router-link to="/read_questionnaire">
              <img src="../../../assets/images/readBtn.png" alt />
            </router-link>
          </li>
        </ul>
      </div>
      <div class="header-tool">
        <div class="addClass" @click="showAddClass">
          <img src="../../../assets/images/addClass.png" height="49" alt />
        </div>
        <div class="refresh" @click="refresh()">
          <img src="../../../assets/images/refresh.png" alt />
        </div>
      </div>
    </div> -->

    <div class="top-common">
      <div style="display:flex;align-items: center;line-height: 72px;" >
        <div class="title-text">问卷分类管理</div>
        <!-- 右边 -->
        <div class="right-btn-box">
          <el-button @click="showAddClass" type="primary" size="mini" class="btn">增加分类</el-button>
          <router-link to="/control_questionnaire_result">
            <el-button type="primary" size="mini" class="btn">评测问卷</el-button>
          </router-link>
          <router-link to="/question_bank">
            <el-button type="primary" size="mini" class="btn">问卷题库</el-button>
          </router-link>
          <router-link to="/read_questionnaire">
            <el-button type="primary" size="mini" class="btn">批阅问卷</el-button>
          </router-link>
        </div>
      </div>    
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="分类名称"
          width="550"
          align="left"
          header-align="center"
        ></el-table-column>
        <!-- <el-table-column prop="measure_title" label="试卷统计" width="250" align="center"></el-table-column>
        <el-table-column prop="measure_title" label="试题统计" width="250" align="center"></el-table-column> -->
        <el-table-column prop="sort" label="排序" width="150" align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.sort"
              size="mini"
              maxlength="2"
              onkeyup="value=value.replace(/[^\d]/g,'') "
              @blur="upSort(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="showAddtestPaper(scope.row)"
              >创建</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="showAddClass(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="delClass(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page" style="text-align: right">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 添加、编辑分类模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="addClass"
      width="30%"
      center
      @close="reset"
    >
      <el-form ref="form1" :model="form1" label-width="80px">
        <el-form-item label="分类名称:">
          <el-input v-model.trim="form1.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addClass = false">取 消</el-button>
        <el-button type="primary" @click="saveClass">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加试卷 -->
    <el-dialog
      :title="title"
      :visible.sync="addtestPaper"
      width="60%"
      center
      :close-on-click-modal="false"
      @close="reset2"
    >
      <el-form ref="form" :model="form" label-width="120px" size="mini">
        <el-row>
          <el-col :span="8">
            <el-form-item label="分类：">
              <el-select v-model="form.qtid" placeholder>
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="(item, key) in tableData"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="题型：">
              <el-select v-model="form.type" placeholder>
                <el-option label="单选题" :value="1"></el-option>
                <el-option label="多选题" :value="2"></el-option>
                <el-option label="判断题" :value="3"></el-option>
                <el-option label="填空题" :value="4"></el-option>
                <el-option label="排序题" :value="5"></el-option>
                <el-option label="计算题" :value="6"></el-option>
                <el-option label="解答题" :value="7"></el-option>
                <el-option label="问答题" :value="8"></el-option>
                <el-option label="作文题" :value="9"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="难易程度：">
              <el-select v-model="form.lv" placeholder>
                <el-option label="1星" :value="1"></el-option>
                <el-option label="2星" :value="2"></el-option>
                <el-option label="3星" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="试题评级：">
              <el-select v-model="form.grade" placeholder>
                <el-option label="容易" :value="1"></el-option>
                <el-option label="中等" :value="2"></el-option>
                <el-option label="偏难" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否共享：">
              <el-radio-group v-model="form.share">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="题目：">
          <el-input
            v-if="form.type == 4"
            type="textarea"
            :rows="2"
            placeholder="示例:
中国的英文单词是( )，而美国的英文单词是( )
"
            v-model="form.title"
          ></el-input>
          <span v-if="form.type == 4"
            >说明：请把填空的文字用( )括起来，可以设置多项</span
          >
          <!-- 其他 -->
          <el-input
            v-if="form.type != 4"
            type="textarea"
            :rows="2"
            v-model="form.title"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="题目参数："
          v-if="form.type == 1 || form.type == 2 || form.type == 5"
        >
          <!-- 单选，多选，判断 -->
          <el-input
            type="textarea"
            :rows="5"
            placeholder="示例：
a:今天星期一
b:今天星期二
c:今天星期三
          "
            v-model="form.parameter"
          ></el-input>
          <span>说明：每个选项占一行，适用于:单选，多选，排序</span>
        </el-form-item>
        <el-form-item label="答案：">
          <!-- 单选，多选，判断 -->
          <el-input
            type="textarea"
            :rows="5"
            placeholder="示例:
a,b,c"
            v-model="form.answer"
            v-if="form.type == 1 || form.type == 2 || form.type == 5"
          ></el-input>
          <span v-if="form.type == 1 || form.type == 2 || form.type == 5"
            >说明：多个答案用逗号隔开</span
          >
          <!-- 填空 -->
          <el-input
            type="textarea"
            :rows="5"
            placeholder="示例:"
            v-model="form.answer"
            v-if="form.type == 4"
          ></el-input>
          <span v-if="form.type == 4"
            >说明：如果是多项填空,则每项的答案换一行.顺序不能乱</span
          >
          <!-- 其他 -->
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.answer"
            v-if="
              form.type != 4 &&
              form.type != 1 &&
              form.type != 2 &&
              form.type != 5
            "
          ></el-input>
          <span
            v-if="
              form.type != 4 &&
              form.type != 1 &&
              form.type != 2 &&
              form.type != 5
            "
            >说明：直接填写正确的答案</span
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addtestPaper = false">取 消</el-button>
        <el-button type="primary" @click="addQuestion">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { saveType, getType, deleteType, saveQue } from "@/api/questionnaire.js";
export default {
  inject: ["reload"],
  data() {
    return {
      form1: {
        name: "",
        id: 0,
      },
      form: {
        qtid: "",
        type: "",
        title: "",
        parameter: "",
        answer: "",
        lv: "",
        grade: "",
        share: "",
      },
      tableData: [],
      addClass: false,
      // 页码
      page: 1,
      pageSize: 15,
      title: "添加题目",
      total: 0,
      addtestPaper: false,
    };
  },
  created() {
    this.loadListData();
  },
  methods: {
    // 页码监听
    handleCurrentChange(val) {
      this.page = val;
      // 根据page加载对应数据
      this.loadListData(val);
    },
    handleSelectionChange() {},
    // 刷新
    refresh() {
      this.reload();
    },
    // 显示
    showAddClass(row) {
      this.addClass = true;
      this.title = "添加分类";
      if (row.id) {
        this.title = "编辑分类";
        let { title, id } = row;
        this.form1.name = title;
        this.form1.id = id;
      }
    },
    // 添加分类
    saveClass() {
      let data = {
        title: this.form1.name,
        id: this.form1.id,
      };
      let msg = "修改成功！";
      if (data.id == 0) {
        delete data.id;
        msg = "添加成功！";
      }
      saveType(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: msg,
            type: "success",
          });
          this.addClass = false;
          this.loadListData();
        }
      });
    },
    // 加载列表
    loadListData() {
      let data = {
        page: this.page,
        pagesize: this.pageSize,
      };
      getType(data).then((res) => {
        if (res.code == 400200) {
          this.tableData = res.data;
          this.total = res.num;
        }
      });
    },
    // 删除分类
    delClass(row) {
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteType({ ids: row }).then((res) => {
            if (res.code == 400200) {
              this.$message({
                showClose: true,
                message: "删除成功",
                type: "success",
              });
              this.loadListData();
            }
          });
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 重置
    reset() {
      this.form1.name = "";
      this.form1.id = 0;
    },
    // 重置模态框
    reset2() {
      this.form.qtid = "";
      this.form.type = "";
      this.form.title = "";
      this.form.parameter = "";
      this.form.answer = "";
      this.form.lv = "";
      this.form.grade = "";
      this.form.share = "";
      // this.form.id = 0;
    },
    // 添加 、 编辑试卷题目模态框
    showAddtestPaper(row) {
      this.addtestPaper = true;
      let { id } = row;
      this.form.qtid = id;
    },
    // 添加试题
    addQuestion() {
      saveQue(this.form).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "添加成功,请继续添加",
            type: "success",
          });
          this.form.title = "";
          this.form.parameter = "";
          this.form.answer = "";
        }
      });
    },
    // 更新排序
    upSort(row) {
      let data = {
        id: row.id,
        title: row.title,
        sort: row.sort,
      };
      saveType(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "更新成功！",
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style lang="less">
.tc-questionnaire_classify {
  // 头部
  .header {
    width: 60%;
    margin: 0 auto;
    display: flex;
    padding-top: 5px;
    .header-operation {
      ul {
        display: flex;
        li {
          //  float: left;
          width: 150px;
          list-style: none;
          padding: 0 30px;
          text-align: center;
          img {
            width: 100%;
            // pointer-events: none;
          }
        }
      }
    }
    .header-tool {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .addClass {
        width: 120px;
        cursor: pointer;
      }
      .refresh {
        width: 120px;
        cursor: pointer;
      }
      img {
        width: 100%;
        pointer-events: none;
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
}
// 带牌表格样式
table{
  color: #100700;
    th {
      color: #4B2000 !important;
      background: #FFDEC6 !important;
      border-right: none !important;
      border-left: none !important;
    }
    td {
      border-right: none !important;
      border-left: none !important;
    }
    .el-button--mini{
      color: #FFAB71 !important;
      padding: 4px 4px;
      background: transparent;
      border: none !important;
      font-size: 14px;
    }
}
</style>